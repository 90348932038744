

import { Button, Typography, Link } from "@suid/material";

import { createSignal } from "solid-js";
import { useNavigate } from "@solidjs/router";
import { SetStoreFunction } from "solid-js/store";
import { Divider, Stack, Dialog, DialogActions, DialogTitle, DialogContent, Box } from "@suid/material";
 
import {FieldPassword} from "../SmallComponents/SmallFieldPassword";
import { getURL } from '../../../utils/fetchAPI'
import toast from 'solid-toast';
import { LockIcon } from "../icons"
import { getUserStore, setUserStore, UserStore } from "../../context/userContext";
import { getText } from '../SmallComponents/Translate'
import { showError } from '../SmallComponents/SmallShowError'
const apiUrl = import.meta.env.VITE_APP_API_URL;

const ConfirmPassword = () => {
    const navigate = useNavigate();

    const [open, setOpen] = createSignal(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [userName, setUserName] = createSignal('');
    const [userPassword, setUserPassword] = createSignal('');
    const [userPassword2, setUserPassword2] = createSignal('');
    const [pwMessage, setPwMessage] = createSignal('');
    //const { userStore } = useUserContext() as { userStore: UserStore };
    //const { setUserStore } = useUserContext() as { setUserStore: SetStoreFunction<UserStore> };
    //const { userStore, setUserStore }  = useUserContext();
    const txtNoMatch = getText("confirmpassword", "nomatch")
    let checkMatch = () => {
        (userPassword() === userPassword2()) ? setPwMessage('') : setPwMessage( txtNoMatch() as string);
    }
    const handleChangePassword = (pw: string) => {
        setUserPassword(pw)
        checkMatch()
    }
    const handleChangePassword2 = (pw: string) => {
        setUserPassword2(pw)
        checkMatch()
    }
    const handleConfirmPassword = () => {
        type LoginUser = {
            status: string,
            redirect: string,
            userId?: string,
            userFirstName?: string,
            userLastName?: string,
            userCompanyId?: string;
            userCompanyName?: string,
            userAvatar?: string,
            userLanguage?: string;
            userCountry?: string;
        }

        const txtPasswordConfirmed = getText("confirmpassword", "success")
        const txtError = getText("default", "error", [{ name: 'during', value: '' }])
     

        // getURL(`${apiUrl}/api/security/passwordreset`, 'POST', { email: userName(), password: userPassword() })
        getURL(`${apiUrl}/security/forgotpassword`, 'POST', { email: userName(), password: userPassword() })
            .then((fetchedData: LoginUser) => {
 
                if (fetchedData.status === '200') {

                    setUserStore({
                        userId: fetchedData.userId as string,
                        userFirstName: fetchedData.userFirstName as string,
                        userLastName: fetchedData.userLastName as string,
                        userCompanyId: fetchedData.userCompanyId as string,
                        userCompanyName: fetchedData.userCompanyName as string,
                        userAvatar: fetchedData.userAvatar as string,
                        userLanguage: fetchedData.userLanguage as string,
                        userCountry: fetchedData.userCountry as string
                    });
                    // setUserStore("userId", fetchedData.userId as string);
                    // setUserStore("userFirstName", fetchedData.userFirstName as string);
                    // setUserStore("userLastName", fetchedData.userLastName as string);
                    // setUserStore("userCompanyId", fetchedData.userCompanyId as string);
                    // setUserStore("userCompanyName", fetchedData.userCompanyName as string);
                    // setUserStore("userAvatar", fetchedData.userAvatar as string);
                    // setUserStore("userLanguage", fetchedData.userLanguage as string);
                    // setUserStore("userCountry", fetchedData.userCountry as string);
                    const userStore = getUserStore();
                    toast.success((txtPasswordConfirmed() as string) + userStore.userFirstName + " " + userStore.userLastName + '. You will be redirected to login.', { duration: 5000, });

                    setOpen(false);
                    navigate("/home")
                } else {
                    showError(txtError() as string, `${fetchedData.status}`);
                }
            }).catch((error) => {
                showError(txtError() as string, `${error.message}`);
            })
    };

    const txtHeader = getText("confirmpassword", "header")
    const txtRule = getText("confirmpassword", "rule")
    const txtPassword = getText("contact", "password")
    const txtReenterPassword = getText("confirmpassword", "reenterpassword")
    const txtClose = getText("default", "close")
    const txtSubmit = getText("confirmpassword", "submit")

    return (
        <>
            <Link href="#" target="none" onClick={handleOpen}>{txtHeader()}</Link>

            {open() && (

                <Dialog open={true} >
                    <Box
                        component="form"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleConfirmPassword();
                        }}
                    >
                        <DialogTitle>
                            {" "}
                            <Typography variant="h4">{txtHeader()}</Typography>
                        </DialogTitle>
                        <DialogContent>

                            <Typography variant="subtitle1">{txtRule()}</Typography>

                            <Stack margin={2} spacing={2}>
                                <FieldPassword
                                    id="pw1" label={txtPassword() as string} textValue=""
                                    valueOnChange={value => handleChangePassword(value)}
                                />
                                <FieldPassword
                                    id="pw2" label={txtReenterPassword() as string} textValue=""
                                    valueOnChange={value => handleChangePassword2(value)}
                                    helperText={pwMessage()}
                                    error={userPassword() !== userPassword2()}
                                />
                            </Stack>
                            <Stack margin={2} spacing={2}>
                                <Divider />
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" color="info" onClick={handleClose}>{txtClose() as string}</Button>
                            <Button variant="contained" startIcon={<LockIcon />} type="submit">{txtSubmit() as string} </Button>
                        </DialogActions>
                    </Box>
                </Dialog>
            )}
        </>
    );
}
export {ConfirmPassword}