/* @refresh reload */
import { render } from "solid-js/web";
import { ErrorBoundary } from 'solid-js';
import { lazy } from "solid-js";

import "./findasapClient.css";
import { Router, Route, Routes, useNavigate } from "@solidjs/router";
 
import { Alert, Button, Stack, Link } from "@suid/material";
import { SplashBackground } from "./src/pages/SplashBackground";
import { ConfirmPassword } from "./src/components/AppMenuBar/ConfirmPassword";
import { ShowLoginPage } from "./src/pages/LoginPage";
//const ShowLoginPage = lazy(() => import("./src/pages/LoginPage"));
import { ShowSharePage } from "./src/pages/SharingPage";
import { ShowFavouritsPage } from "./src/pages/FavouritsPage";
import { ShowOnboardingPage } from "./src/pages/OnboardingPage";
import { ShowCustomerCVPage } from "./src/pages/CustomerCVPage";
import { ShowCustomerAssignment } from "./src/pages/CustomerAssignmentPage";
import { ShowCustomerJobsPage } from "./src/pages/CustomerJobPage";
import { ShowCustomerWorkflowPage } from "./src/pages/CustomerWorkflowPage";
import { ShowCustomerJobAddPage } from "./src/pages/CustomerJobAddPage";
import { ShowCustomerJobEditPage } from "./src/pages/CustomerJobEditPage";
import { ShowCustomerJobRoleViewPage } from "./src/pages/CustomerJobRoleViewPage";

import { ShowCustomerJobAssignmentViewPage } from "./src/pages/CustomerJobAssignmentViewPage";
import { ShowCustomerJobRoleEditPage } from "./src/pages/CustomerJobRoleEditPage";
import { ShowCustomerMatchingPage } from "./src/pages/CustomerMatchingPage";
import { ShowCustomerCandidates } from "./src/pages/CustomerCandidatesPage";
import { ShowCustomerToDoPage } from "./src/pages/CustomerToDosPage";
import { ShowOtherCVPage } from "./src/pages/PreviewCVPage";
import { ShowCandidateMyCVPage } from "./src/pages/CandidateMyCVPage"; 
import { ShowCandidateToDoPage } from "./src/pages/CandidateToDosPage";
import { ShowCandidateJobsPage } from "./src/pages/CandidateJobPage"; 
import { ShowCandidateMyJobsPage } from "./src/pages/CandidateMyJobsPage"; 
import { ShowCandidateMyJobRolePage }  from "./src/pages/CandidateMyJobRolePage";
import { ShowCandidateJobRolePage } from "./src/pages/CandidateJobRolePage";
import { ShowCandidateMatchingPage } from "./src/pages/CandidateMatchingPage";
import { ShowCandidateWorkflowPage } from "./src/pages/CandidateWorkflowPage";
import { ShowCandidateAssignment } from "./src/pages/CandidateAssignmentPage";
import { ShowSettingCompanyPage } from "./src/pages/SettingCompanyPage";
import { ShowSettingUserPage } from "./src/pages/SettingUserPage";
import { BasicAppBar } from "./src/components/AppMenuBar/AppBarMenu";
import { HomePage } from "./src/pages/HomePage";
import { MyThemeProvider } from "./src/components/AppMenuBar/AppBarToogleColorMode";
import toast, { Toaster } from "solid-toast";

const root = document.getElementById("root");

if (import.meta.env.DEV && !(root instanceof HTMLElement)) {
  throw new Error(
    "Root element not found. Did you forget to add it to your index.html? Or maybe the id attribute got mispelled?"
  );
}

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <Alert sx={{ px: 1, py: 1 }} severity="error">
        <h2>
          We are so sorry but we have some issues loading the requested page.
        </h2>
        <h3>404 Page not found</h3>
        <br />
        <Stack spacing={2} direction="row">
          <Button onClick={() => window.history.go(-1)}>Try to go back</Button>
          <Button onClick={() => navigate("/home")}>Try to go home</Button>
        </Stack>
      </Alert>
    </>
  );
}

render(
  () => (
    <>
      <ErrorBoundary fallback={(e) => <div>Error: {e.message}</div>}>
        <MyThemeProvider>
          <Toaster
            position="bottom-left"
            // Spacing between each toast in pixels
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              // Define default options that each toast will inherit. Will be overwritten by individual toast options
              className: "",
              duration: 2000,
              style: {
                background: "#363636",
                color: "#fff",
              },
            }}
          />

          <Router>
            <Routes>
              <ErrorBoundary
                fallback={(e) => (
                  <div>
                    <Alert sx={{ px: 1, py: 1 }} severity="error">
                      Error: {e.message} .
                    </Alert>
                  </div>
                )}
              >
                <Route path="/" component={BasicAppBar} />
                <Route path="/home" component={HomePage} />
                <Route path="/login" component={ShowLoginPage} />
                <Route path="/changepasswordform" component={ConfirmPassword} />
                <Route path="/onboard" component={ShowOnboardingPage} />
                <Route path="/share" component={ShowSharePage} />
                <Route path="/favourit" component={ShowFavouritsPage} />
                <Route path="/customermatching" component={ShowCustomerMatchingPage} />
                <Route path="/customerworkflow" component={ShowCustomerWorkflowPage} />
                <Route path="/customertodo" component={ShowCustomerToDoPage} />
                <Route path="/customerjob/:id?" component={ShowCustomerJobsPage} />
                <Route path="/customerjobedit" component={ShowCustomerJobEditPage} />
                <Route path="/customerjobadd" component={ShowCustomerJobAddPage} />

                <Route path="/customerjobroleview/:id" component={ShowCustomerJobRoleViewPage} />
                <Route path="/customerjobroleedit" component={ShowCustomerJobRoleEditPage} />
                <Route path="/customerassignment" component={ShowCustomerAssignment} />
                <Route path="/customerjobassignmentview/:assignmentId" component={ShowCustomerJobAssignmentViewPage} />
           
                <Route path="/customercv" component={ShowCustomerCVPage} />
                <Route path="/candidates/:id" component={ShowCustomerCandidates} />
                <Route path="/viewcv/:id" component={ShowOtherCVPage} />
                <Route path="/mycv" component={ShowCandidateMyCVPage} />
                <Route path="/myjobs" component={ShowCandidateMyJobsPage } />
                <Route path="/myjobrole/:id" component={ShowCandidateMyJobRolePage} />
                <Route path="/candidatetodo" component={ShowCandidateToDoPage} />
                <Route path="/candidatejob" component={ShowCandidateJobsPage} />
                <Route path="/candidatejobrole" component={ShowCandidateJobRolePage} />
                <Route path="/candidatematching" component={ShowCandidateMatchingPage} />
                <Route path="/candidateworkflow/:name/:id?" component={ShowCandidateWorkflowPage}/>
                <Route path="/candidateassignment" component={ShowCandidateAssignment} />
                <Route path="/user" component={ShowSettingUserPage} />
                <Route path="/company" component={ShowSettingCompanyPage} />
                <Route path="*" component={PageNotFound} />
              </ErrorBoundary>
            </Routes>
          </Router>
        </MyThemeProvider>
      </ErrorBoundary>
    </>
  ),
  root
);

// component={<ShowCustomerJobEdit >Edit Job</ShowCustomerJobEdit>}
//  component={<ShowCustomerJobEdit >Add Job</ShowCustomerJobEdit>}
// component={<ShowCustomerJobRoleEdit>Edit Jobrole" </ShowCustomerJobRoleEdit>}
//<Route path="/onboard/:token/:email/:firstname/:lastname" component={ShowOnboardingPage} />
//<Route path="/onboard/:token/:email/:firstname/:lastname" component={ShowOnboardingPage} />
